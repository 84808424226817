@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

ol li {
    margin-bottom: 16px;
}

.pointer {
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.visibility-hidden {
    visibility: hidden;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
}

.button-spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 0.15em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    margin: 0 12px 0 3px;
    vertical-align: -0.125em;
}

.push-main {
    padding-top: 78px;
}

.push-main-lg {
    min-height: 100vh;
    padding-top: 144px;
}

#lg-nav .nav-item a.active {
    color: rgba(255, 255, 255, 1);
}

.p-15 {
    padding: 15px 0;
}

.p-tb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-rl-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.h3 {
    font-size: 1.25rem;
}

.form-group {
    margin-bottom: 0.65rem;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #d5dae2;
    opacity: 1;
}

.form-control[readonly] {
    background-color: #fdfdfd;
    opacity: 1;
}

.dropdown-menu a {
    color: #505050;
}

.dropdown-menu a:hover {
    color: #63de7c;
}

@media (max-width: 576px) {
    .space-2 {
        padding-bottom: 1rem !important;
    }

    .space-2 {
        padding-top: 1rem !important;
    }

    .col-small {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media (min-width: 576px) {
    .p-tb-15 {
        padding-top: 0;
        padding-bottom: 0;
    }

    .p-rl-30 {
        padding-right: 0;
        padding-left: 0;
    }
    .h3 {
        font-size: 1.75rem;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md .u-header__nav-last-item {
        display: block;
    }
    .navbar-expand-md .u-header__nav-last-item a {
        display: block;
        margin-top: 2rem;
    }
}

@media (max-width: 991.98px) and (min-width: 768px) {
    .navbar-expand-md .u-header__navbar-brand {
        padding-top: 0;
    }
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.badge-other {
    color: #fff;
    background-color: #377dff;
    text-transform: capitalize;
}

.progress {
    text-transform: capitalize;
}

.nav-shadow {
    box-shadow: 0 1px 3px 0 rgba(140, 152, 164, 0.25);
}

.nav-classic .nav-link {
    color: #77838f;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 0.72rem 1.5rem;
    margin-bottom: -0.125rem;
    transition: 0.3s;
}

#list-view div.card {
    border-radius: 0;
}

#list-view div.card:first-of-type {
    border-top-right-radius: 0.3125rem;
    border-top-left-radius: 0.3125rem;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

#list-view div.card:last-of-type {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}

#xs-nav a.btn-icon {
    position: relative;
    line-height: 0;
    font-size: 0.85rem;
    width: 2.125rem;
    height: 2.125rem;
    padding: 0;
    border-radius: 6rem;
}

.card-col-0 {
    flex: 1 0;
}

.card-col-2 {
    flex: 1 0 2%;
}

.card-col-10 {
    flex: 1 0 10%;
}

.card-col-20 {
    flex: 1 0 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.center-icon {
    margin-bottom: 2rem;
    max-width: 260px;
}

.checkbox-outline__label {
    border: 2px solid #e7eaf3;
    cursor: pointer;
}

.card-deck-flex {
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-flex {
    flex: 0 1 32%;
}

.card-flex-2 {
    flex: 0 1 49%;
}

.flex-auto {
    flex: 1 1 auto;
}

@media (min-width: 992px) {
    .card-flex {
        flex: 0 1 24%;
    }
}

.progress {
    background-color: #ececec;
}

.progress,
.progress-bar {
    border-radius: 0;
}

@media (min-width: 992px) {
    .rounded-lg-left {
        border-top-left-radius: 0.3125rem !important;
        border-bottom-left-radius: 0.3125rem !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .progress,
    .progress-bar {
        border-radius: 10rem;
    }
}

#basicsAccordion .card {
    border-radius: 0;
    border-top: none;
}

#basicsAccordion .btn-icon {
    color: #377dff;
    font-size: 0.9rem;
}

.sb-avatar__text span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.btn-pill {
    border-radius: 6.1875rem !important;
}

.tab-content {
    display: flex;
    flex-grow: 1;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.push-main {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    padding-top: 78px;
    overflow: hidden;
}
.push-main-lg {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    padding-top: 144px;
    overflow: hidden;
}

.bg-light {
    background-color: #f8f9fa !important;
    display: flex;
    flex-grow: 1;
}

.auto-complete-container {
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 30px;
    margin: 0;
}

.auto-complete-item {
    list-style: none;
    color: #8c98a4;
    cursor: pointer;
}

.auto-complete-item:hover {
    color: #045cff;
}

// Fade Container
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// Custom Editable Select **Maybe a foucs state should be considered
.react-select.pristine__control.css-yk16xz-control,
.react-select.pristine__control.css-yk16xz-control:hover,
.react-select.pristine__control.css-1pahdxg-control,
.react-select.pristine__control.css-1pahdxg-control:hover,
.react-select.pristine__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.pristine__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.pristine__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.pristine__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid #d5dae2;
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

.react-select.danger__control.css-yk16xz-control,
.react-select.danger__control.css-yk16xz-control:hover,
.react-select.danger__control.css-1pahdxg-control,
.react-select.danger__control.css-1pahdxg-control:hover,
.react-select.danger__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.danger__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.danger__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.danger__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid #de4437;
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

.react-select.success__control.css-yk16xz-control,
.react-select.success__control.css-yk16xz-control:hover,
.react-select.success__control.css-1pahdxg-control,
.react-select.success__control.css-1pahdxg-control:hover,
.react-select.success__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.success__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.success__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.success__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid rgba(0, 201, 167, 0.5);
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    appearance: none;
    background: transparent;
    background-image: url('../../assets/expand_more.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
}

.select-icon {
    height: 50px;
    width: 50px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 1rem center/8px 10px;
}

// Side Nav
.show-nav {
    animation: show-nav 0.5s forwards;
    -webkit-animation: show-nav 0.5s forwards;
}

.hide-nav {
    animation: hide-nav 0.5s forwards;
    -webkit-animation: hide-nav 0.5s forwards;
}

@keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 0;
    }
}

@-webkit-keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 0;
    }
}

@-moz-keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 0;
    }
}

@keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

@-webkit-keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

@-moz-keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

#sage-splash-screen,
#login-screen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #252c33;
}

#sage-splash-screen .center,
#login-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#sage-splash-screen .logo {
    max-width: 370px;
    margin: 0 auto;
}

#sage-splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
}

#sage-splash-screen .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
}

#sage-splash-screen .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .left,
#sage-splash-screen .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#sage-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #87a439;
    border-bottom-color: transparent;
    border-radius: 50%;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .left {
    left: 0;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .right {
    right: 0;
}

#sage-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }
    100% {
        transform: rotate(360deg) scale(0.5);
    }
}

@keyframes left-wobble {
    0%,
    100% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

@keyframes right-wobble {
    0%,
    100% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
}

@keyframes sporadic-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}

#sage-splash-screen .login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

#sage-splash-screen .login .btn,
.btn-wide {
    padding: 0.75rem 5rem;
}

.bg-gradient {
    background: rgb(0, 201, 255);
    background: linear-gradient(
        45deg,
        rgba(0, 201, 255, 0.6) 25%,
        rgba(146, 254, 157, 0.6) 100%
    );
}

.bg-white {
    background: #ffffff;
}

.bg-dark {
    background: #252c33 !important;
}

.form-shadow {
    box-shadow: 0px 10px 20px #0000001f;
}

.form-round {
    border-radius: 1rem;
}

.spinner-border {
    display: block;
    width: 3rem;
    height: 3rem;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    margin: 0 auto;
}

.shadow-1 {
    --tw-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
        0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}

.shadow-2 {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}

.shadow-3 {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.main {
    padding: 24px 24px 6px 24px;
}

.main .container {
    max-width: 1570px;
    width: 100%;
    margin: 0 auto;
}

.text-note {
    font-size: 0.75rem;
}

.text-green {
    color: #62de7c;
}

.text-active-green {
    color: #62de7c;
}

.text-red {
    color: #ca3938;
}

.text-gray {
    color: #778490;
}

.text-dark-gray {
    color: #505050;
}

.dropzone {
    border: 2px dashed $primary;
    border-radius: 10px;
    min-height: 200px;
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.import-file {
    color: $primary;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.import-file:hover {
    color: #2bca4b;
}

.link-text {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
}

.link-text:hover {
    color: #2bca4b;
}
// ToolBar styles

.user-button {
    color: #242424;
    display: inline-flex;
    min-width: 4rem;
    min-height: 4rem;
    padding: 0.6rem 1.6rem;
}

.user-button.btn-link:hover {
    color: #242424;
}

.users-name {
    font-size: 0.813rem;
    font-weight: 600;
    text-transform: capitalize;
}

.users-role {
    color: #6b7280;
    font-size: 0.688rem;
    font-weight: 500;
}

.users-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
}

.users-img img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}

// Profile settings

.profile-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 90px;
    height: 90px;
    font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
    font-size: 42px;
    border-radius: 10%;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
}

.profile-img img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}

// SideNav

.sidenav .material-icons-round,
.material-symbols-outlined {
    color: #fff;
    font-size: 32px;
}

.material-symbols-outlined {
    font-size: 22px;
}

.sidenav .nav-item {
    margin: 12px 0;
    width: 100%;
}

.sidenav .nav-link {
    border-radius: 4px;
    display: block;
    line-height: 0.5;
    padding: 0.5rem;
}

.sidenav .nav-item .nav-link.active {
    background-color: #5a5a5a;
}

// OrgNav

.orgnav {
    background-color: #688027;
}

.orgnav .nav-item .nav-link.active {
    background-color: #87a439;
}

.sidenav .nav-link,
.sidenav .nav-link a {
    color: #fff;
}

// OrgNav

.orgnav {
    background-color: #688027;
}

.orgnav .nav-link {
    color: #fff;
}

// Widgets

.widget-label {
    color: #77838f;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
}

.widget-data {
    font-size: 42px;
    font-weight: 600;
    line-height: 1;
    margin: 10px 0 0;
}

.widget-data small {
    font-size: 40%;
}

@media (max-width: 1600px) {
    .widget-label {
        font-size: 16px;
    }

    .widget-data {
        font-size: 36px;
    }
}

@media (max-width: 1200px) {
    .widget-label {
        font-size: 14px;
    }

    .widget-data {
        font-size: 32px;
    }
}

// Org Table

.org-item {
    border-bottom: 1px solid #e1e1e1;
    color: #505050;
    display: flex;
    height: 68px;
}

.badge-status {
    font-size: 100%;
    width: 100%;
    max-width: 140px;
}

.badge-device-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    width: 100%;
    max-width: 140px;
    height: 30px;
    z-index: 0 !important;
}
// Activity Table

.infinite {
    overflow-y: scroll;
    cursor: pointer;
}

.infinite .alert {
    border-radius: 0;
    margin-bottom: 0;
}

.infinite .alert span {
    font-size: 80%;
}

.infinite .alert-danger {
    background-color: transparent;
    border-bottom: 1px solid #f7dcd9;
}

// Org Detail

.org-detail-item {
    min-width: 175px;
    height: auto;
}

.org-detail-image {
    min-width: 197px;
    height: auto;
}

@media (max-width: 1358px) {
    .org-detail-image {
        display: none;
    }
}

@media (max-width: 940px) {
    .org-detail-item {
        margin-top: 1em;
        min-width: 275px;
    }
}

@media (max-width: 730px) {
    .org-detail-item {
        min-width: 175px;
    }
}

// Confirm Footer

.cancel-button,
.cancel-button:hover {
    color: #ca3938 !important;
    border: none;
    background-color: transparent !important;
    cursor: pointer !important;
}

.delete-button,
.delete-button:hover {
    color: #ca3938 !important;
    border: 1px solid #ca3938 !important;
    background-color: transparent !important;
    cursor: pointer !important;
}

.footer-btn-container {
    flex: 0 0 50%;
    max-width: 50%;
}

.allow-button {
    color: #ffffff !important;
    border: none;
    background-color: #ca3938 !important;
    cursor: pointer !important;
}

// Manager Form

.button-underlined-gray,
.button-underlined-gray:hover,
.button-underlined-gray:focus,
.button-underlined-gray:active {
    color: #b5b5b5 !important;
    text-decoration: underline;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

// Contact list

.contacts-list-search {
    width: 100%;
}

.contact-list-item {
    border-bottom: 1px solid #e1e1e1;
    color: #505050;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 68px;
    cursor: pointer;
}

.contact-list-item:hover {
    background-color: #ececec;
}

.org-selector-item:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.invite-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 114px;
    height: 30px;
    border-radius: 7px;
    cursor: pointer;
}

.invite-all {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #505050;
    width: 136px;
    height: 30px;
    border-radius: 7px;
    cursor: pointer;
}

.badge-gray {
    background-color: #8c98a4;
}

.badge-green {
    background-color: #63de7c;
}

.badge-pending {
    background-color: #c9c53b;
}

.badge-active {
    background-color: #00c9a7;
}

.badge-suspended {
    background-color: #de4437;
}

.badge-tenant {
    background-color: #3388ff;
    color: #ffffff;
}

.form-field-label {
    text-transform: uppercase;
    font-weight: 600;
    color: #1e2022;
    line-height: 1.2em;
    letter-spacing: 0px;
    font-size: 12.8px;
}

.copy-link-btn {
    color: #505050;
    background-color: #d5dae2;
    cursor: pointer;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.copy-link-btn:hover {
    background-color: #bebebe;
    transform: scale(1.01);
}

.copy-link-btn-copied {
    background-color: #63de7c;
    color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.device-card-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.device-card {
    display: flex;
    justify-content: space-between;
    height: 200px;
    min-width: 280px;
    max-width: 460px;
    border: 1 solid #e3e3e3;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.device-card:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
}

@media (max-width: 1280px) {
    .device-card-container {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 980px) {
    .device-card-container {
        grid-template-columns: auto;
    }
}

.device-image {
    height: 100%;
}

.device-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 25px;
    padding-bottom: 31px;
    padding-top: 36px;
}

.device-name {
    font-size: 16px;
    color: #505050;
}

.device-info-text {
    font-size: 12px;
    color: #8c98a4;
}

.test-connection-button,
.test-connection-button:hover {
    color: #fff !important;
    border: none;
    background-color: #778490 !important;
    cursor: pointer !important;
    font-size: 14px;
    line-height: 1.2em;
    width: 70%;
    min-width: 140px;
}

.radio-button {
    cursor: pointer;
    padding-right: 25px;
}

.radio-button__input {
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    padding: 0;
}

.radio-button__control {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: #505050;
    border: 2px solid #666;
    border-radius: 24px;
}

.radio-button__input:checked + .radio-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    width: 10px;
    height: 10px;
    background-color: #505050;
    border-radius: 12px;
}

.radio-button__input:checked + .radio-button__control {
    border-color: #505050;
}

.radio-button__control {
    transform: scale(0.75);
}

.radio-button__label {
    font-size: 12.8px;
    line-height: 1.2em;
    color: #505050;
}

.notyf__toast {
    //     justify-content: center;
    //     align-items: center;
    //     animation: applyRed 9s linear;
    cursor: pointer;
}

// .notyf__ripple {
//     display: none;
// }

// @keyframes applyRed {
//     from {
//         background-color: rgb(61, 199, 99);
//     }

//     to {
//         background-color: red;
//     }
// }

.remote-participant-video {
    min-height: 430px;
    width: 100%;
}

.video-loader {
    color: #fff;
    opacity: 0.6;
}

.btn-outline-secondary {
    color: #77838f;
    border-color: #77838f;
    background-color: #fff !important;
}

.btn-outline-secondary:hover {
    color: #77838f !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select-multiple {
    font-size: 16px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select {
    height: 46px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select .ant-select-arrow {
    margin-top: -9px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select-multiple .ant-select-selector {
    padding: 1px 12px;
}

.anticon-close svg {
    margin-bottom: -2px;
}

.anticon-clock-circle svg {
    margin-bottom: 0;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
